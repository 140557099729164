import React, { PureComponent } from "react"

import Layout from "../components/layout"
import Main from "../components/Main"
import SEO from "../components/seo"
import Splash from "../components/Splash"

class IndexPage extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hasLoaded: false,
    }
  }

  componentDidMount() {
    this.imgLoaded()
  }

  imgLoaded = () => {
    let self = this
    var pageImgs = document.images,
      imageLength = pageImgs.length,
      counter = 0
    ;[].forEach.call(pageImgs, function(img) {
      if (img.complete) {
        incrementCounter()
      } else {
        img.addEventListener("load", incrementCounter, false)
      }
    })

    function incrementCounter() {
      counter++
      if (counter === imageLength) {
        setTimeout(function() { 
          self.setState({ hasLoaded: true })
        }, 3000);
      }
    }
  }

  render() {
    let { hasLoaded } = this.state

    return (
      <>
      <Splash active={hasLoaded} progress={this.imagesLoaded} />
      <Layout>
        <SEO title="Home" />
        <Main />
      </Layout>
      </>
    )
  }
}

export default IndexPage