/* eslint-disable react/prop-types */
import React from "react"
import styled from "styled-components"
import media from "../style/media"

const Container = styled.section`
  position: fixed;
  z-index: 999;
  width: 100vw;
  height: 110vh;
  margin: 0;
  background-color: #273782;
  opacity: 1;
  &.disable {
    transition: 500ms ease-out;
    opacity: 0;
    z-index: -10;
  }
`
const ProgressBar = styled.div`
  border-radius: 50px;
  height: 5px;
  width: 150px;
  position: absolute;
  display: block;
  left: 40%;
  top: 50%;
  transform: translate(-50%, -50%);
  div {
    border-radius: 50px;
    height: 100%;
    background-color: #F94F46;
    transition: width 0.5s ease;
    -webkit-animation: load 3s linear;
    -moz-animation: load 3s linear;
    -o-animation: load 3s linear;
    animation: load 3s linear;
  }
  @-webkit-keyframes load {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  @-moz-keyframes load {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  @-o-keyframes load {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  @keyframes load {
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
  }
  ${media.tablet`
  left: 50%;
  `}
`

const Splash = props => {
  return (
    <Container className={props.active ? "disable" : null}>
      <ProgressBar>
        <div className={props.active ? "disable" : null} />
      </ProgressBar>
    </Container>
  )
}

export default Splash
