import React, { useState, useRef, useEffect } from "react"
import styled, { keyframes } from 'styled-components'
import media from "../../style/media"
import Swiper from 'swiper'
import 'swiper/css/swiper.min.css'

import Logo from "../../images/logo.svg"
import Circle from "../../images/circle.png"
import Triangle from "../../images/triangle.png"

const Pulse = keyframes`
  0% {
    transform: scale(0.8);
  }50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
`

const MainCon = styled.div`
  font-family: sans-serif;
  height: 65vh;
  .swiper-container {
    overflow: visible;
    height: inherit;
    max-height: 100vh;
    .pulse, .pulse-down {
      float: right;
      border-radius: 50%;
      width: 25px;
      height: 25px;
      img {
        position: absolute;
      }
      .circ {
        width: 25px;
        opacity: 0.3;
        animation: 2s ease-in-out infinite ${Pulse};
      }
      .tri {
        margin: 8px;
        width: 10px;
      }
      .up {
        transform: rotate(180deg);
      }
    }
    .down {
      .tri {
        transform: rotate(180deg);
      }
    }
    .swiper-wrapper {
      .swiper-slide {
        .contactInfo {
          display: none;
        }
        img {
          width: 100%;
        }
        p {
          a {
            color: white;
            text-decoration: none;
            font-size: 0.8em;
          }
          .orangeText {
            color: #F94F46;
          }
        }
        .text{
          color: white;
          text-align: end;
          font-size: 1.2em;
          line-height: 1.2;
        }
        .infoCont {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 0.5fr 1fr;
          margin: 55% 0 0;
          img {
            grid-column: 1/3;
            grid-row: 1;
          }
          .info {
            grid-column: 2;
            grid-row: 2;
            display: flex;
            justify-content: flex-end;
          }
        }
        .contact {
          font-size: 20px;
          letter-spacing: 0.5px;
          display: grid;
          grid-template-columns: 0.5fr 1fr;
          grid-template-rows: 1fr 0.5fr;
          img {
            grid-column: 2;
            grid-row: 1;
            width: 100%;
          }
          .address {
            grid-column: 1/3;
            grid-row: 2;
            .location {
              a {
                color: #0a132d !important;
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }
  }
  ${media.mobileL`
    .swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          .infoCont {
            margin: 0;
            img {
              width: 100%;
              grid-column: 2/3;
            }
          }
          .contact {
            grid-template-columns: 1fr 0.5fr;
            grid-template-rows: 0.5fr 2fr;
            img {
              grid-column: 2/3;
              width: 100%;
            }
            p {
              line-height: 1;
            }
          }
        }
      }
    }
  `}
  ${media.laptop`
  .swiper-container {
    .pulse {
      display: none;
    }
    .swiper-wrapper {
      .swiper-slide {
        .contactInfo {
          display: block;
        }
        img {
          width: 100%;
        }
        p {
          a {
            color: white;
            text-decoration: none;
            font-size: 0.8em;
          }
          .orangeText {
            color: #F94F46;
          }
        }
        .text{
          color: white;
          text-align: end;
          font-size: 1.5em;
          line-height: 1.2;
        }
        .infoCont {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: 0.5fr 1fr;
          margin: 10vh 0 0;
          img {
            grid-column: 2/3;
            width: 80%;
            justify-self: end;
            grid-row: 1;
          }
          .info {
            grid-column: 2;
            grid-row: 2;
            display: flex;
            justify-content: flex-end;
          }
        }
        .contact {
          font-size: 20px;
          letter-spacing: 0.5px;
          display: grid;
          grid-template-columns: 0.5fr 1fr;
          grid-template-rows: 1fr 0.5fr;
          img {
            grid-column: 2;
            grid-row: 1;
            width: 100%;
          }
          .address {
            grid-column: 1/3;
            grid-row: 2;
            .location {
              a {
                color: #0a132d !important;
                text-decoration: none !important;
              }
            }
          }
        }
      }
    }
  }
  `}
`

const MainMob = () => {
  const swiperElement = useRef(null)
  const swiper = useRef(null)
  const [activeSlide, setActiveSlide] = useState(0)

  useEffect(() => {
    swiper.current = new Swiper(swiperElement.current, {
      direction: 'vertical',
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      effect: 'fade',
      fadeEffect: {
        crossFade: true
      },
      speed: 600,
      breakpoints: {
        480: {
          slidesPerView: 1,
          noSwiping: false,
          allowSlidePrev: true,
          allowSlideNext: true
        },
        1024: {
          slidesPerView: 1,
          noSwiping: true,
          allowSlidePrev: false,
          allowSlideNext: false
        }
      },
      on: {
        transitionEnd: () => {
          if (swiper.current) {
            setActiveSlide(swiper.current.activeIndex)
          }
        },
      },
    })
  }, [])

  const slideTo = () => {
    if (swiper.current.activeIndex === 0) {
      swiper.current.slideTo(1, 800)
    } else if (swiper.current.activeIndex === 1) {
      swiper.current.slideTo(0, 800)
    }
  }

  return (
    <MainCon>
      <div ref={swiperElement} className="swiper-container">
        <div className="swiper-wrapper">

          <div className="swiper-slide">
            <div className="contactInfo">
              <p><a href="tel:+27112681294">+27 11 268 1294</a> <br/>
              <a href="mailto:info@andpartner.co.za">info@andpartner.co.za</a></p>
            </div>
            <div className="infoCont">
              <img src={Logo} alt="logo" /> <br/>
              <div className="info">
                <p className="text">a product, <br/> services and <br/> revenue design <br/> <p className="orangeText">consultancy.</p> </p>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="contact">
              <img src={Logo} alt="logo" /> <br/>
              <div className="address">
                <p><a href="tel:+27112681294">+27 11 268 1294</a> <br/>
                <a href="mailto:info@andpartner.co.za">info@andpartner.co.za</a></p>
                <div class="location">
                  <p><a href="https://goo.gl/maps/TKdzz4AiuFNuUV1q6" target="_blank" rel="noreferrer">
                      The Conservatory, <br/>
                      2nd Floor, <br/>
                      13 Baker Street, <br/>
                      Rosebank, 2196
                  </a></p>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div
          className={
            activeSlide === 0 ? "pulse up" : "pulse down" &&
            activeSlide === 1 ? "pulse down" : "pulse up"
          }
          onClick={() => slideTo()}
        >
          <img className="circ" alt="circle" src={Circle} />
          <img className="tri" alt="triangle" src={Triangle} />
        </div>
      </div>
    </MainCon>
  )
}

export default MainMob
