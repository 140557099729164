import React from "react"
import styled from 'styled-components'
import media from "../../style/media"

import MainMob from "../Main/mainmob.js"

const MainCon = styled.div`
  font-family: sans-serif;
  height: 65vh;
   .web {
      display: none;
    }
    .mobile {
      display: block;
    }
  ${media.laptop`
    .mobile {
      display: none;
    }
    .web {
      display: block;
    }
  `}
`

const MainContent = () => {
  return (
    <MainCon>
      <MainMob />
    </MainCon>
  )
}

export default MainContent
